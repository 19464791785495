import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode'; // Use default import for jwt-decode
import axios from 'axios';

const GoogleLoginButton = ({ onSuccess, onError, isLogin = 'tutor' }) => {
  
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const [location, setLocation] = useState({ latitude: 15.8700, longitude: 100.9925 });
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setError(null);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    getLocation();
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        

        const userInfo = await axios
          .get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then(res => res.data);

        let payload = {
          first_name: userInfo?.given_name,
          last_name: userInfo?.family_name,
          email: userInfo?.email,
          provider: {
            id: userInfo?.sub, // Google's user ID
            name: 'google'
          },
          location: {
            coordinates: [ location.longitude, location.latitude ],
            type: "Point"
          }
        }

        let isSocialLoginType = 'teacher';
        if(isLogin == 'parent'){
          isSocialLoginType = 'parents';
          payload.isVerified = true;
        }

        let data = JSON.stringify(payload);

        let config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/${isSocialLoginType}/social-login`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data: data
        };

        const apiResponse = await axios.request(config);

        onSuccess(apiResponse);
      } catch (error) {
        onError(error);
      }
    },
    onError,
  });

  return (
    <>
    {isLogin === 'parent' ? (
      <button onClick={login} className="btn btn-outline-primary ms-2">
        <img src={require("../assets/images/google1.png")} alt="Google Icon" style={{ width: "20px", marginRight: "10px" }} />
        Parent Login
      </button>
    ) : (
      <button onClick={login} className="btn text-dark bg-white border-0 shadow mb-3 bg-white rounded">
        <img src={require("../assets/images/google1.png")} alt="Google Icon" style={{ width: "20px", marginRight: "10px" }} />
        Login
      </button>
    )}
    </>
  );
};

export default GoogleLoginButton;
